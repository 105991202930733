import Divider from '@solid-ui-components/Divider'
import Footer from '../../blocks/Footer/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Layout from '../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import React from 'react'
import Seo from '@solid-ui-components/Seo'
import { Container } from 'theme-ui'
import { graphql } from 'gatsby'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import theme from './intel/_theme'
import WhyChooseUs from '@solid-ui-blocks/Features/Block04'
import Process from '@solid-ui-blocks/Features/Block03'

const Services02 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Our services" />
      {/* Modals */}
      <ModalWithTabs content={content['contact-en']} />
      {/* Blocks */}
      <Header content={content['header-light-en']} />
      <Container title="" variant="full" sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space="5" />
      <WhyChooseUs content={content['services-details']} />
      <Divider space="5" />
      <Process title="" content={content['companies-photo']} />
      <Divider space="5" />
      <Footer title="" content={content['footer-en']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageSiteServicesBlockContentEn {
    allBlockContent(
      filter: { page: { in: ["site/services-en", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Services02
